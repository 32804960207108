"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBlockModel = exports.BlockContentModel = void 0;
var lodash_1 = require("lodash");
var jsonParse_1 = require("../../../utils/jsonParse");
var common_1 = require("../../../utils/common");
var utilBlocks_1 = require("../utils/utilBlocks");
exports.BlockContentModel = {
    html: '',
    string: null,
    list: null,
    settings: { bordered: true },
    links: null,
};
function getBlockModel(block) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z;
    var newValues = {
        autonumeric: (_a = block.autonumeric) !== null && _a !== void 0 ? _a : true,
        emptyValue: (_b = block.emptyValue) !== null && _b !== void 0 ? _b : false,
        id: block.id,
        isLib: (_c = block.isLib) !== null && _c !== void 0 ? _c : false,
        isReceived: (_d = block.isReceived) !== null && _d !== void 0 ? _d : false,
        isRequired: (_e = block.isRequired) !== null && _e !== void 0 ? _e : true,
        name: (_f = block.name) !== null && _f !== void 0 ? _f : '',
        numeration_depth: (_g = block.numeration_depth) !== null && _g !== void 0 ? _g : 0,
        subType: (_h = block.subType) !== null && _h !== void 0 ? _h : '',
        template_id: (_j = block.template_id) !== null && _j !== void 0 ? _j : null,
    };
    // собираем модель блока из данных, пришедших с сервера
    if ((0, utilBlocks_1.isTemplateElement)(block)) {
        var libraryElemNumber = (0, lodash_1.get)(block, 'fakeElement_id.templateElementNumber', '');
        // порядковый номер блоков при отображении в шаблоне
        var order = block.templateElementNumber;
        // блок варианта секции
        if (block.parentElement_id) {
            order = common_1.AttrUtils.generateKey(block.parentElement_id, block.groupElementNumber);
        }
        else if (block.fakeElement_id) {
            // блок в ББ
            order = block.fakeElement_id.templateElementNumber;
        }
        var uniqValues = {
            order: order,
            canBeEdited: false,
            content: (_k = (0, jsonParse_1.jsonParse)(block.content)) !== null && _k !== void 0 ? _k : (0, lodash_1.cloneDeep)(exports.BlockContentModel),
            external_params: (0, jsonParse_1.jsonParse)(block.external_params),
            groupId: block.group_id,
            groupName: (_l = block.group_name) !== null && _l !== void 0 ? _l : '',
            isContentEditable: true,
            libraryTemplate_id: block.fakeTemplate_id,
            libraryElement_id: block.fakeElement_id,
            libraryElemNumber: parseInt(libraryElemNumber) || null,
            sectionId: (_m = block.parentElement_id) !== null && _m !== void 0 ? _m : null,
            type: block.elementType,
            sectionVariant: (_o = block.subType) !== null && _o !== void 0 ? _o : '',
            parent_id: '',
        };
        return Object.assign(uniqValues, newValues);
    }
    else {
        // собираем модель блока из данных существующего блока или создаем новый
        return Object.assign(newValues, {
            action: block.action,
            content: (0, lodash_1.cloneDeep)((_p = block.content) !== null && _p !== void 0 ? _p : exports.BlockContentModel),
            external_params: (_q = (0, lodash_1.cloneDeep)(block.external_params)) !== null && _q !== void 0 ? _q : null,
            groupId: (_r = block.groupId) !== null && _r !== void 0 ? _r : null,
            groupName: (_s = block.groupName) !== null && _s !== void 0 ? _s : '',
            isContentEditable: (_t = block.isContentEditable) !== null && _t !== void 0 ? _t : true,
            libraryElemNumber: (_u = block.libraryElemNumber) !== null && _u !== void 0 ? _u : null,
            libraryElement_id: (_v = block.libraryElement_id) !== null && _v !== void 0 ? _v : null,
            libraryTemplate_id: (_w = block.libraryTemplate_id) !== null && _w !== void 0 ? _w : null,
            order: block.order,
            sectionId: (_x = block.sectionId) !== null && _x !== void 0 ? _x : null,
            type: block.type,
            sectionVariant: block.sectionVariant,
            subType: (_y = block.subType) !== null && _y !== void 0 ? _y : '',
            template_id: (_z = block.template_id) !== null && _z !== void 0 ? _z : null,
        });
    }
}
exports.getBlockModel = getBlockModel;
